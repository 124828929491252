import Pagination from '@/components/Pagination/Pagination.vue'
import GraphQlMixin from '@/mixins/graphql.mixin'
import QueryMixin from '@/mixins/query.mixin'
import AssetMixin from '@/mixins/asset.mixin'

export default {
  mixins: [GraphQlMixin, QueryMixin, AssetMixin],
  components: {
    Pagination
  },
  data() {
    return {
      assets: [],
      assetsCount: 0,
      footerProps: { 'items-per-page-options': [5, 10, 25, 50] },
      options: { page: 1, itemsPerPage: 10 },
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Serial Number / ICCID', value: 'serialNumber', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Operation Status', value: 'operationStatus', sortable: false },
        { text: 'Usage', value: 'usage', sortable: false },
        { text: 'Initial Deployment Date', value: 'initialDeploymentDate', sortable: false },
        { text: 'Region', value: 'region', sortable: false },
        { text: 'Assignee', value: 'assignee', sortable: false },
        { text: 'OS', value: 'os', sortable: false },
        { text: 'Type', value: 'type', sortable: false }
      ],
      search: '', // search input model
      keyword: '', // the last searched keyword
      loading: false,
      dirty: false
    }
  },
  watch: {
    options: {
      handler() {
        this.submit()
      },
      deep: true
    }
  },
  methods: {
    searchClicked() {
      this.keyword = this.search
      this.submit()
    },
    async submit() {
      if (!this.keyword || this.loading) {
        return
      }

      this.assets = []

      this.dirty = true
      this.loading = true
      const { data, error } = await this.$apollo
        .query({
          // Query
          query: this.$gql`
          query assetSearch($take: Int, $skip: Int, $orderBy: [OrderBy], $query: String!) {
            assetSearch(
              take: $take
              skip: $skip
              orderBy: $orderBy
              query: $query
            ) {
              items {
                id
                name
                serialNumber
                status
                operationStatus
                region
                assignee
                usage
                initialDeploymentDate
                os
                type
              }
              totalCount
            }
          }
        `,
          variables: {
            take: this.options.itemsPerPage,
            skip: this.options.itemsPerPage === -1 ? 0 : this.options.itemsPerPage * (this.options.page - 1),
            orderBy: [{ path: 'name' }],
            query: this.keyword
          },
          fetchPolicy: 'network-only'
        })
        .catch((error) => {
          this.graphQLOnError(`Failed to connect to server. ${error.toString()}`)
          this.assets = []
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })

      if (data && data.assetSearch) {
        this.assets = data.assetSearch.items
        this.assetsCount = data.assetSearch.totalCount
      } else if (error) {
        this.graphQLOnError(`Failed to get asset data from server. ${error.toString()}`)
        this.assets = []
      }
    }
  },
  created() {
    if (!this.ability.can('read', 'App')) {
      this.$router.push('/403')
    }
  }
}
